import { React, useState, useEffect } from "react";
import { db } from "../../firebase.js";
import { collection, addDoc, setDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import FUSIHowDoesItWork from "../copies/fusiHowDoesItWork.jsx";

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Fade,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import BrandLogo from "../brandLogo.jsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const FUSIRequestPage = () => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [respondentName, setRespondentName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [needRawAnswer, setNeedRawAnswer] = useState(false);
  const [errors, setErrors] = useState({});
  const [answers, setAnswers] = useState("");

  useEffect(() => {
    const fetchCurrentQuestion = async () => {
      try {
        const docRef = doc(
          db,
          process.env.REACT_APP_FUSI_CURRENT_QUESTION_COLLECTION_NAME,
          "current_questions"
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setQuestion(docSnap.data().english);
        } else {
          console.error("No current question found.");
        }
      } catch (error) {
        console.error("Error fetching the current question:", error);
      }
    };

    fetchCurrentQuestion();
  }, []);

  const maxCharacters = 1000;

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);

  const navigate = useNavigate();

  const validateStep = () => {
    const newErrors = {};

    if (step === 2) {
      if (!name.trim()) newErrors.name = "Name is required";
      if (!email.trim()) newErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(email))
        newErrors.email = "Email is invalid";
      if (email !== confirmEmail)
        newErrors.confirmEmail = "Emails do not match";
    }

    if (step === 1) {
      if (!answers.trim()) newErrors.answers = "Answer is required";
      else if (answers.trim().length < 50)
        newErrors.answers = "Your answer must contain at least 50 characters";
      if (!respondentName.trim())
        newErrors.respondentName = "Respondent name is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      try {
        const requestRef = await addDoc(
          collection(db, process.env.REACT_APP_FUSI_REQUEST_COLLECTION_NAME),
          {
            creator_name: name,
            respondent_name: respondentName,
            email,
            question,
            need_raw_answer: needRawAnswer,
            create_time: new Date().toISOString(),
          }
        );

        const requestID = requestRef.id;

        const formattedAnswers = {
          creator_answers: answers,
        };

        await setDoc(
          doc(db, process.env.REACT_APP_FUSI_ANSWER_COLLECTION_NAME, requestID),
          formattedAnswers
        );

        console.log("Feedback request and answers saved with ID:", requestID);

        navigate(`/daily/success/${requestID}`);

        setName("");
        setRespondentName("");
        setEmail("");
        setQuestion([]);
        setAnswers([]);
        setNeedRawAnswer(false);
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#F8F6F4",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        alt="Brand logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
          width: "40px",
          height: "40px",
        }}
      >
        <Link to="/">
          <BrandLogo
            sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
          />
        </Link>
      </Box>
      <Fade in={true} timeout={700} key={step}>
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <form onSubmit={handleSubmit} noValidate>
            {step === 2 && (
              <>
                <Typography
                  variant="h5"
                  color="black"
                  sx={{
                    marginBottom: 4,
                    fontWeight: "600",
                    fontSize: { xs: "1.2rem", md: "1.4rem" },
                    color: "black",
                  }}
                >
                  Awesome, can you tell me a bit about yourself?
                </Typography>
                {/* Name Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Your Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>

                {/* Email Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Email (to receive the compatibility report)"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>

                {/* Confirm Email Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Confirm Email"
                    fullWidth
                    type="email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    error={!!errors.confirmEmail}
                    helperText={errors.confirmEmail}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )}

            {step === 1 && (
              <>
                <Typography sx={{ marginBottom: 1, color: "grey.700" }}>
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Sincero Daily
                  </Box>{" "}
                  checks your compatibility with another person on
                  thought-provoking questions.
                </Typography>

                <FUSIHowDoesItWork />

                <Typography
                  sx={{
                    marginBottom: 2,
                    fontWeight: "bold",
                    fontSize: { xs: "1.1rem", sm: "1.3rem" },
                  }}
                >
                  {question || "Loading..."}
                </Typography>

                <TextField
                  label="Make your answer as detailed as possible, in any languages"
                  fullWidth
                  multiline
                  rows={3}
                  value={answers}
                  onChange={(e) => setAnswers(e.target.value)}
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                  }}
                  slotProps={{
                    htmlInput: {
                      maxLength: maxCharacters,
                    },
                    input: {
                      disableUnderline: true,
                      sx: {
                        borderRadius: "20px",
                        border: "1px solid transparent",
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    },
                    inputLabel: {
                      sx: {
                        color: "grey.500",
                        whiteSpace: "normal", // Allow the text to wrap
                        wordWrap: "break-word", // Break long words if needed
                        overflow: "visible", // Prevent text clipping
                        textOverflow: "clip", // Ensure text doesn't appear truncated
                        textAlign: "left",
                      },
                    },
                  }}
                  error={!!errors.answers}
                  helperText={errors.answers}
                />

                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    marginTop: 1,
                    marginBottom: 4,
                  }}
                >
                  {answers.length}/{maxCharacters} characters
                </Typography>

                {/* Need Raw feedback field 
                <Box
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Need Raw Answer</InputLabel>
                    <Select
                      value={needRawAnswer}
                      onChange={(e) => setNeedRawAnswer(e.target.value)}
                      label="Need raw answer"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        border: "none", // Remove border
                        "& fieldset": { border: "none" }, // Remove default Material-UI border
                        "&:hover fieldset": { border: "none" }, // Prevent border on hover
                        "&.Mui-focused fieldset": { border: "none" }, // Prevent border on focus
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "10px",
                          },
                        },
                      }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    title="If yes, you will receive the raw answers from you and another person. In most cases, we discourage our users to receive them unless neccessary as this usually result in unhonest answer."
                    slotProps={{
                      tooltip: {
                        sx: {
                          fontSize: "0.8rem",
                          padding: "10px",
                        },
                      },
                    }}
                  >
                    <IconButton sx={{ marginLeft: "10px", padding: 0 }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                */}

                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Your partner's name (e.g. John)"
                    fullWidth
                    value={respondentName}
                    onChange={(e) => setRespondentName(e.target.value)}
                    error={!!errors.respondentName}
                    helperText={errors.respondentName}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )}

            <Box>
              {step > 1 && (
                <Button
                  onClick={handleBack}
                  variant="contained"
                  sx={{
                    padding: 1.5,
                    borderRadius: "50px",
                    textTransform: "none",
                    width: "100px",
                    backgroundColor: "primary.light",
                    color: "white",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <ArrowBackIosNewIcon />
                </Button>
              )}
              {step < 2 && (
                <Button
                  onClick={() => {
                    if (validateStep()) handleNext();
                  }}
                  variant="contained"
                  sx={{
                    padding: 1.5,
                    borderRadius: "50px",
                    textTransform: "none",
                    width: "100px",
                    backgroundColor: "primary",
                    color: "white",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              )}
              {step === 2 && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: 1.5,
                    borderRadius: "50px",
                    textTransform: "none",
                    width: "100px",
                    backgroundColor: "primary",
                    color: "white",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <CheckIcon />
                </Button>
              )}
            </Box>
          </form>
        </Container>
      </Fade>
    </Box>
  );
};

export default FUSIRequestPage;
