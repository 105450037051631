import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import LockIcon from "@mui/icons-material/Lock";
import RateReviewIcon from "@mui/icons-material/RateReview";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";

const items = [
  {
    icon: <RocketLaunchIcon />,
    title: "Leverage the latest technology",
    description:
      "Our platform uses AI and other cutting-edge tools to help people communicate effortlessly and authentically, fostering deeper relationships in the digital age.",
  },
  {
    icon: <HealthAndSafetyIcon />,
    title: "Safe place only",
    description:
      "Sincero provides a safe and welcoming space where people can share their thoughts and feelings without fear of judgment. We prioritize privacy and anonymity, ensuring that every exchange is genuine, respectful, and meaningful.",
  },
  {
    icon: <InsertEmoticonIcon />,
    title: "Fun is not a compromise",
    description:
      "Connection isn't just about deep conversations — it's also about having fun, so we design experiences that make connecting with others both engaging and exciting.",
  },
];

export default function KeyFeatures() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 6 },
        pb: { xs: 4, sm: 6 },
        paddingLeft: { xs: 2, sm: 6 },
        paddingRight: { xs: 2, sm: 6 },
        color: "white",
        bgcolor: "transparent",
        borderRadius: { xs: 4, sm: 8 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "80%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            color="black"
            component="h2"
            variant="h2"
            fontWeight="600"
            gutterBottom
            sx={{ fontSize: { xs: "1.4rem", md: "1.6rem" } }}
          >
            What is Sincero?
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1, color: "grey.700" }}>
            At Sincero, our mission is to facilitate human connection in today's
            increasingly divided world. We believe that meaningful interactions
            can bridge gaps and bring people closer, no matter where they are.
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  flexWrap: "wrap",
                  color: "inherit",
                  p: 3,
                  minHeight: { lg: "220px" },
                  borderColor: "hsla(220, 25%, 25%, 0.3)",
                  backgroundColor: "#031221",
                  borderRadius: 8,
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: "medium" }}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "grey.400",
                    }}
                  >
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
