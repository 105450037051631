import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Helmet } from "react-helmet";
import BrandLogo from "../brandLogo.jsx";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

// Import Material UI Components
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Fade,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

const FUSIAnswerPage = () => {
  const { requestId } = useParams();
  const [questionData, setQuestionData] = useState(null);
  const [answers, setAnswers] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [respondentEmail, setRespondentEmail] = useState("");

  // Fetch the question details when the page loads
  useEffect(() => {
    const fetchQuestionData = async () => {
      setLoading(true);
      const docRef = doc(
        db,
        process.env.REACT_APP_FUSI_REQUEST_COLLECTION_NAME,
        requestId
      );

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setQuestionData(docSnap.data());
        setLoading(false);
      } else {
        console.log("No such document!");
        setLoading(false);
      }
    };

    fetchQuestionData();
  }, [requestId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const docRef = doc(
          db,
          process.env.REACT_APP_FUSI_ANSWER_COLLECTION_NAME,
          requestId
        );

        await updateDoc(docRef, { respondent_answers: answers });

        setSubmitted(true);

        const functions = getFunctions();
        const checkCompatibility = httpsCallable(
          functions,
          "checkCompatibility"
        );
        console.log("Request ID:", requestId);
        console.log("Respondent Email:", respondentEmail);
        await checkCompatibility({
          requestId: requestId,
          respondentEmail: respondentEmail,
        });

        setAnswers("");
      } catch (error) {
        console.error("Error submitting answer: ", error);
      }
    }
  };

  const maxCharacters = 1000;

  const currentDomain = window.location.origin;

  const validateForm = () => {
    const newErrors = {};

    if (!answers.trim()) newErrors.answers = "Answer is required";
    else if (answers.trim().length < 50)
      newErrors.answers = "Your answer must contain at least 50 characters";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  if (submitted) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F8F6F4",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          alt="Brand logo"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px",
          }}
        >
          <MuiLink component={RouterLink} to="/" underline="hover">
            <BrandLogo
              sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
            />
          </MuiLink>
        </Box>
        <Fade in={submitted} timeout={700}>
          <Container sx={{ textAlign: "center" }}>
            <Typography
              color="black"
              sx={{
                marginBottom: 2,
                fontWeight: "700",
                fontSize: { xs: "1.4rem", sm: "1.8rem" },
                color: "black",
              }}
            >
              All good, thanks! 🫡
            </Typography>

            <Typography
              variant="body1"
              sx={{ marginBottom: 3, color: "grey.800" }}
            >
              “If love is the answer, could you please rephrase the question?” -
              Lily Tomlin
            </Typography>

            <Button
              variant="contained"
              color="primary"
              sx={{ padding: 2, borderRadius: "50px", textTransform: "none" }}
              href="/daily"
            >
              Check my compatibility with another person
            </Button>
          </Container>
        </Fade>
      </Box>
    );
  }

  return (
    <div>
      {questionData && (
        <Helmet>
          <title>
            Check your compatibility with {questionData.creator_name}!
          </title>
          <meta
            name="description"
            content={`Check your compatibility with ${questionData.creator_name} through Sincero.`}
          />
          <meta
            property="og:title"
            content={`Check your compatibility with ${questionData.creator_name}!`}
          />
          <meta
            property="og:description"
            content="Provide your honest feedback anonymously and securely through Sincero."
          />
          <meta
            property="og:url"
            content={`${currentDomain}/answer/${requestId}`}
          />
        </Helmet>
      )}

      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#F8F6F4",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          alt="Brand logo"
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px",
          }}
        >
          <MuiLink component={RouterLink} to="/" underline="hover">
            <BrandLogo
              sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
            />
          </MuiLink>
        </Box>

        <Fade in={true} timeout={700}>
          <Container maxWidth="sm" sx={{ textAlign: "center" }}>
            {loading ? (
              <Typography variant="body1">Loading feedback form...</Typography>
            ) : questionData ? (
              <>
                <Typography sx={{ marginBottom: 4, color: "grey.700" }}>
                  Hey{" "}
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    {questionData.respondent_name}
                  </Box>
                  , let's see if you and{" "}
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    {questionData.creator_name}
                  </Box>{" "}
                  are compatible on this topic:
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Typography
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      fontSize: { xs: "1.1rem", sm: "1.3rem" },
                    }}
                  >
                    {questionData.question}
                  </Typography>

                  <TextField
                    label="Make your answer as detailed as possible"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    value={answers}
                    onChange={(e) => setAnswers(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      htmlInput: {
                        maxLength: maxCharacters,
                      },
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                    error={!!errors.answers}
                    helperText={errors.answers}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      marginTop: 1,
                      marginBottom: 2,
                    }}
                  >
                    {answers.length}/{maxCharacters} characters
                  </Typography>

                  <Box sx={{ marginBottom: 2 }}>
                    <TextField
                      label="Email (if you want to receive the report)"
                      variant="outlined"
                      fullWidth
                      value={respondentEmail}
                      onChange={(e) => setRespondentEmail(e.target.value)}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                      }}
                      slotProps={{
                        input: {
                          disableUnderline: true,
                          sx: {
                            borderRadius: "20px",
                            border: "1px solid transparent",
                            "& fieldset": {
                              border: "none",
                            },
                          },
                        },
                        inputLabel: {
                          sx: {
                            color: "grey.500",
                          },
                        },
                      }}
                    />
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: 1.5,
                      borderRadius: "50px",
                      textTransform: "none",
                      width: "100px",
                      backgroundColor: "primary",
                      color: "white",
                    }}
                  >
                    <CheckIcon />
                  </Button>
                  <Typography
                    variant="body2"
                    sx={{ marginTop: 4, color: "grey.700", fontSize: "0.8rem" }}
                  >
                    Email is needed if you want to also receive the
                    compatibility report.{" "}
                    {questionData.need_raw_feedback ? (
                      <>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                          As requested, the creator can see your answers in
                          exact wordings.
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box component="span" sx={{ fontWeight: "bold" }}>
                          The feedback seeker cannot see your answers in
                          exacting wordings but only a summarized compatibility
                          report.
                        </Box>
                      </>
                    )}{" "}
                    <MuiLink href="/" target="_blank" underline="hover">
                      Click here to learn more about us.
                    </MuiLink>
                  </Typography>
                </form>
              </>
            ) : (
              <Typography variant="body1">Feedback form not found</Typography>
            )}
          </Container>
        </Fade>
      </Box>
    </div>
  );
};

export default FUSIAnswerPage;
