import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // FAQ data array
  const faqData = [
    {
      id: "panel1",
      question: "What is Sincero Daily?",
      answer: (
        <>
          Each day, we provide a{" "}
          <Link href="/daily" target="_blank" underline="hover">
            thought-provoking question
          </Link>{" "}
          that users can answer and share with someone special through a unique
          link. Once both participants have responded, our AI agent analyzes
          their answers and, if they choose, delivers a personalized
          compatibility report. It's a fun and insightful way to learn more
          about each other and strengthen relationships, one question at a time.
        </>
      ),
    },
    {
      id: "panel3",
      question: "What is Sincero Feedback?",
      answer: (
        <>
          First, the feedback seeker fills out a{" "}
          <Link href="/request" target="_blank" underline="hover">
            form
          </Link>{" "}
          to ask a question. During this setup, they have the option to choose
          whether they want to view the original feedback or only the summarized
          version. Once the request is created, a unique link is generated that
          can be shared with others. People can then provide feedback
          anonymously through this link. The feedback seeker will receive a
          summary of all responses at desired frequency.
        </>
      ),
    },
    {
      id: "panel4",
      question: "Are all the feedback anonymous in Sincero Feedback?",
      answer: "Yes.",
    },
    {
      id: "panel5",
      question: "Can I see the exact answers from users?",
      answer: (
        <>
          You have the option to choose to receive the raw answers in Sincero
          Feedback, but not in Sincero Daily.
        </>
      ),
    },
  ];

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 6, sm: 8 },
        pb: { xs: 6, sm: 8 },
        pl: { xs: 2, sm: 2 },
        pr: { xs: 2, sm: 2 },
        position: "static",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 2, sm: 4 },
      }}
    >
      <Typography
        component="h2"
        variant="h2"
        fontWeight="600"
        sx={{
          color: "text.primary",
          textAlign: { xs: "left", md: "center" },
          fontSize: { xs: "1.4rem", md: "1.6rem" },
          width: "100%",
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box>
        {faqData.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
            sx={{
              borderRadius: 2,
              mb: 1,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              position: "static",
              backgroundColor: "#F8F6F4",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${faq.id}-content`}
              id={`${faq.id}-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
