import { React, useState } from "react";
import { db } from "../../firebase.js";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";

import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Fade,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import BrandLogo from "../brandLogo.jsx";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HowDoesItWork from "../copies/howDoesItWork.jsx";

const FeedbackRequestPage = () => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [errors, setErrors] = useState({});
  const [confirmEmail, setConfirmEmail] = useState("");
  const [frequency, setFrequency] = useState("");
  const [needRawFeedback, setNeedRawFeedback] = useState(false);
  const [needRecurringReport, setNeedRecurringReport] = useState(false);
  const [reportTime, setReportTime] = useState("");

  const navigate = useNavigate();

  const handleNext = () => setStep((prev) => prev + 1);
  const handleBack = () => setStep((prev) => prev - 1);

  const validateStep = () => {
    const newErrors = {};
    if (step === 3) {
      if (!name.trim()) newErrors.name = "Name is required";
      if (!email.trim()) newErrors.email = "Email is required";
      else if (!/\S+@\S+\.\S+/.test(email))
        newErrors.email = "Email is invalid";
      if (email !== confirmEmail)
        newErrors.confirmEmail = "Emails do not match";
    }
    if (step === 1) {
      if (!question.trim()) newErrors.question = "Question is required";
    }
    if (step === 2) {
      if (needRecurringReport) {
        if (!frequency.trim()) {
          newErrors.frequency = "Frequency is required";
        }
      } else {
        if (!reportTime.trim()) {
          newErrors.reportTime = "Report generation date is required";
        } else {
          const selectedDate = new Date(reportTime);
          const today = new Date();

          // Reset time components for accurate comparison
          selectedDate.setHours(0, 0, 0, 0);
          today.setHours(0, 0, 0, 0);

          if (selectedDate <= today) {
            newErrors.reportTime =
              "Report generation date must be later than today";
          }
        }
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      try {
        const formattedReportTime = needRecurringReport
          ? null
          : new Date(reportTime + "T20:56:00.000Z").toISOString();

        const docRef = await addDoc(
          collection(
            db,
            process.env.REACT_APP_FEEDBACK_REQUEST_COLLECTION_NAME
          ),
          {
            name,
            email,
            question,
            need_raw_feedback: needRawFeedback,
            need_recurring_report: needRecurringReport,
            create_time: new Date().toISOString(),
            last_report_time: new Date().toISOString(),
            report_time: formattedReportTime,
            report_frequency: needRecurringReport ? frequency : null,
            need_rating: false,
          }
        );

        console.log("Document written with ID: ", docRef.id);

        sessionStorage.setItem("submittedEmail", email);
        navigate(`/request/success/${docRef.id}`);

        setName("");
        setEmail("");
        setQuestion("");
        setNeedRawFeedback();
        setFrequency("");
        setNeedRecurringReport(false);
        setReportTime("");
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    }
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const handleNeedRawFeedback = (event) => {
    setNeedRawFeedback(event.target.value);
  };

  const handleNeedRecurringReportChange = (event) => {
    setNeedRecurringReport(event.target.checked);
    if (event.target.checked) {
      setReportTime("");
    } else {
      setFrequency("");
    }
  };

  const handleReportTimeChange = (event) => {
    setReportTime(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#F8F6F4",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        alt="Brand logo"
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
          width: "40px",
          height: "40px",
        }}
      >
        <Link to="/">
          <BrandLogo
            sx={{ height: 50, marginRight: "10px", cursor: "pointer" }}
          />
        </Link>
      </Box>
      <Fade in={true} timeout={700} key={step}>
        <Container maxWidth="sm" sx={{ textAlign: "center" }}>
          <form onSubmit={handleSubmit} noValidate>
            {step === 3 && (
              <>
                <Typography
                  variant="h5"
                  color="black"
                  sx={{
                    marginBottom: 4,
                    fontWeight: "600",
                    fontSize: { xs: "1.2rem", sm: "1.4rem" },
                    color: "black",
                  }}
                >
                  Finally, can you tell me a bit about yourself?
                </Typography>
                {/* Name Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>

                {/* Email Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Email (to receive summary report)"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>

                {/* Confirm Email Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Confirm Email"
                    fullWidth
                    type="email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    error={!!errors.confirmEmail}
                    helperText={errors.confirmEmail}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )}

            {step === 1 && (
              <>
                <Typography sx={{ marginBottom: 1, color: "grey.700" }}>
                  <Box
                    component="span"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    Sincero Feedback
                  </Box>{" "}
                  helps you to collect genuine answers to any questions and
                  sends you a summarized report at your desired frequency.
                </Typography>

                <HowDoesItWork />

                <Typography
                  variant="h5"
                  color="black"
                  sx={{
                    marginBottom: 2,
                    fontWeight: "600",
                    fontSize: { xs: "1.2rem", sm: "1.4rem" },
                    color: "black",
                  }}
                >
                  Type your question
                </Typography>

                {/* Question Field */}
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="What do you think of my management style?"
                    fullWidth
                    multiline
                    rows={4}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    error={!!errors.question}
                    helperText={errors.question}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "20px",
                    }}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        sx: {
                          borderRadius: "20px",
                          border: "1px solid transparent",
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      },
                      inputLabel: {
                        sx: {
                          color: "grey.500",
                          whiteSpace: "normal", // Allow the text to wrap
                          wordWrap: "break-word", // Break long words if needed
                          overflow: "visible", // Prevent text clipping
                          textOverflow: "clip", // Ensure text doesn't appear truncated
                          textAlign: "left",
                        },
                      },
                    }}
                  />
                </Box>
              </>
            )}

            {step === 2 && (
              <>
                <Typography
                  variant="h5"
                  color="black"
                  sx={{
                    marginBottom: 4,
                    fontWeight: "600",
                    fontSize: { xs: "1.2rem", sm: "1.4rem" },
                    color: "black",
                  }}
                >
                  Good start! How do you want to receive your summary report?
                </Typography>
                <Box sx={{ marginBottom: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={needRecurringReport}
                        onChange={handleNeedRecurringReportChange}
                        color="primary"
                      />
                    }
                    label="I want recurring report"
                  />
                </Box>

                {needRecurringReport ? (
                  // Frequency Dropdown Menu
                  <Box sx={{ marginBottom: 2 }}>
                    <FormControl fullWidth error={!!errors.frequency}>
                      <InputLabel>Report frequency</InputLabel>
                      <Select
                        value={frequency}
                        onChange={handleFrequencyChange}
                        label="Frequency"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "20px",
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              borderRadius: "10px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="weekly">Weekly</MenuItem>
                        <MenuItem value="monthly">Monthly</MenuItem>
                      </Select>
                      {errors.frequency && (
                        <Typography variant="caption" color="error">
                          {errors.frequency}
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                ) : (
                  // Report Generation Date Picker
                  <Box sx={{ marginBottom: 2 }}>
                    <TextField
                      label="Report Generation Date"
                      type="date"
                      fullWidth
                      value={reportTime}
                      onChange={handleReportTimeChange}
                      error={!!errors.reportTime}
                      helperText={errors.reportTime}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                      }}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                          sx: {
                            color: "grey.500",
                          },
                        },
                        input: {
                          disableUnderline: true,
                          sx: {
                            borderRadius: "20px",
                            border: "1px solid transparent",
                          },
                        },
                      }}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel>Need Raw Answer</InputLabel>
                    <Select
                      value={needRawFeedback}
                      onChange={handleNeedRawFeedback}
                      label="Need Raw Answer"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: "10px",
                          },
                        },
                      }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    title="If yes, you will receive all the answers at their original wordings together with AI summarization. However, all feedback remain anonymous."
                    slotProps={{
                      tooltip: {
                        sx: {
                          fontSize: "0.8rem",
                          padding: "10px",
                        },
                      },
                    }}
                  >
                    <IconButton sx={{ marginLeft: "10px", padding: 0 }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )}

            <Box>
              {step > 1 && (
                <Button
                  onClick={handleBack}
                  variant="contained"
                  sx={{
                    padding: 1.5,
                    borderRadius: "50px",
                    textTransform: "none",
                    width: "100px",
                    backgroundColor: "primary.light",
                    color: "white",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <ArrowBackIosNewIcon />
                </Button>
              )}
              {step < 3 && (
                <Button
                  onClick={() => {
                    if (validateStep()) handleNext();
                  }}
                  variant="contained"
                  sx={{
                    padding: 1.5,
                    borderRadius: "50px",
                    textTransform: "none",
                    width: "100px",
                    backgroundColor: "primary",
                    color: "white",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <ArrowForwardIosIcon />
                </Button>
              )}
              {step === 3 && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: 1.5,
                    borderRadius: "50px",
                    textTransform: "none",
                    width: "100px",
                    backgroundColor: "primary",
                    color: "white",
                    margin: "10px 5px 0 5px",
                  }}
                >
                  <CheckIcon />
                </Button>
              )}
            </Box>
          </form>
        </Container>
      </Fade>
    </Box>
  );
};

export default FeedbackRequestPage;
